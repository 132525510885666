import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';

type MessagingPayload = {
  domainRoute: string;
  action: string;
  message: string;
  phoneNumber: string;
  useV2Endpoint: boolean;
};

export const messagingServiceApi = createApi({
  reducerPath: 'messagingServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.rtsproMessagingServiceUrl }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation<string, MessagingPayload>({
      query: ({ phoneNumber, message, useV2Endpoint = false, ...messagePayload }) => {
        const cleanedPhone = (phoneNumber.length === 11 ? '+' : '+1') + phoneNumber;
        return {
          url: useV2Endpoint ? '/v2/message' : '/message',
          method: 'POST',
          data: {
            ...messagePayload,
            body: {
              message,
              phoneNumber: cleanedPhone,
            },
          },
        };
      },
    }),
  }),
});

export const { useSendMessageMutation } = messagingServiceApi;
